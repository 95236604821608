import OpenAISVGLogo from './OpenAISVGLogo'

// Primary Chat Window
const ChatBox = ({chatLog, setChatInput, handleSubmit, chatInput, t}) =>
    <section className="chatbox">
        <div className="chat-log">
            <ChatMessage key={0}  message={{user:"gpt", message:t('welcome-message')}}/>
            {chatLog.map((message, index) => (
                <ChatMessage key={index} message={message}/>
            ))}
            <div id="page-bottom"></div>
        </div>
        <div className="chat-input-holder">
            <form className="form" onSubmit={handleSubmit}>
                <input
                    rows="1"
                    value={chatInput}
                    onChange={(e) => setChatInput(e.target.value)}
                    placeholder={t("chat.box.placeholder")}
                    className="chat-input-textarea"
                    autoFocus={true}></input>
                <button className="submit" type="submit">{t("send.button.label")}</button>
            </form>
        </div>
    </section>

// Individual Chat Message
const ChatMessage = ({message}) => {
    return (
        <div className={`chat-message ${message.user === "gpt" && "chatgpt"}`}>
            <div className="chat-message-center">
                <div className={`avatar ${message.user === "gpt" && "chatgpt"}`}>
                    {message.user === "gpt" ? <OpenAISVGLogo/> : <div>You</div>}
                </div>
                <div className="message">
                    {message.message}
                </div>
            </div>
        </div>
    )
}

export default ChatBox