import "./normal.css";
import "./App.css";
import {useState} from "react";
import SideMenu from "./SideMenu";
import ChatBox from "./ChatBox";
import {useTranslation} from 'react-i18next';

function App() {
    const {t} = useTranslation();
    const [chatInput, setChatInput] = useState("");
    const [temperature, setTemperature] = useState(0.5);
    const [chatLog, setChatLog] = useState([
        // {
        //     user: "gpt",
        //     message: t('welcome-message'),
        // }

    ]);

    // clear chats
    function clearChat() {
        setChatLog([]);
    }

    async function handleSubmit(e) {
        e.preventDefault();
        let chatLogNew = [...chatLog, {user: "me", message: `${chatInput}`}];
        setChatInput("");
        setChatLog(chatLogNew);
        scrollToBottom();

        // fetch response to the api combining the chat log array of messages and seinding it as a message to localhost:3000 as a post
        const messages = chatLogNew.map((message) => message.message).join("\n");
        let response;
        let data;
        try {
            let apiUrl = "https://reefshow-api.inventmarine.com/";
            if (window.location.href.includes('localhost')) {
                apiUrl = "http://localhost:3081/";
            }

            response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    message: messages
                })
            });

            data = await response.json();
        } catch (error) {
            console.error("err", error)
            let errorMessage = error.message;
            if (error.message.includes('429')) {
                errorMessage = 'Ô caraio!  Para de floodar o chat! Se floodar eu não vou ler, porra!';
            }
            data = {
                message: errorMessage
            }
        }

        // const formattedMessage = data.message.replace(/(?:\r\n|\r|\n)/g, '<br>');
        const formattedMessage = data.message;

        setChatLog([...chatLogNew, {user: "gpt", message: `${formattedMessage}`}]);
        scrollToBottom();
    }

     function scrollToBottom () {
        const scrollToTheBottomChatLog = document.getElementsByClassName("chat-log")[0];
        console.log("scrollToTheBottomChatLog: ", scrollToTheBottomChatLog);
        console.log("scrollToTheBottomChatLog.scrollHeight: ", scrollToTheBottomChatLog.scrollHeight);
        // scrollToTheBottomChatLog.scrollTop = scrollToTheBottomChatLog.scrollHeight + 400;
         let pageBottom = document.querySelector("#page-bottom")
         pageBottom.scrollIntoView();
    }

    function handleTemp(temp) {
        if (temp > 1) {
            setTemperature(1);
        } else if (temp < 0) {
            setTemperature(0);
        } else {
            setTemperature(temp);
        }
    }

    return (
        <div className="App">
            <SideMenu
                setTemperature={handleTemp}
                temperature={temperature}
                clearChat={clearChat}
                t={t}
            />
            <ChatBox
                chatInput={chatInput}
                chatLog={chatLog}
                setChatInput={setChatInput}
                handleSubmit={handleSubmit}
                t={t}
            />
        </div>
    );
}

export default App;
