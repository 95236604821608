import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    "welcome-message": "Hello! Ask anything about the Reef Show Youtube videos!",
                    "menu.clear.chat": "Clear chat!",
                    "chat.box.placeholder": "Ask here.",
                    "send.button.label": "Send"
                }
            }, pt: {
                translation: {
                    "welcome-message": "Alô pessoal! Pergunte qualquer coisa sobre os vídeos do canal Reef Show no Youtube!",
                    "menu.clear.chat": "Reinicie o chat.",
                    "chat.box.placeholder": "Pergunta ai!",
                    "send.button.label": "Enviar"
                }
            }
        }
    });

export default i18n;