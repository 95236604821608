
const SideMenu = ({
  clearChat,
  currentModel,
  setCurrentModel,
  models,
  setTemperature,
  temperature,
                    t
}) => (
  <aside className="sidemenu">
    <div className="side-menu-button" onClick={clearChat}>
      {t('menu.clear.chat')}
    </div>
  </aside>
);

const Button = ({ onClick, text }) => (
  <div className="button-picker" onClick={onClick}>
    {text}
  </div>
);

export default SideMenu;
